/* OversizedInstallations.css */

.oversized-installations-page {
    background-color: white;
    color: black; /* Set text color to black for better visibility */
    padding: 100px;
  }
  
  .oversized-installations-gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .oversized-installations-preview {
    display: flex;
    justify-content: center;
    gap: 10px; /* Increase the gap between thumbnails if needed */
  }
  
  .thumbnail {
    width: 200px; /* Adjust the width of the thumbnails */
    height: 200px; /* Adjust the height of the thumbnails */
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .thumbnail.active {
    border: 2px solid #333; /* Border for active thumbnail */
  }
  
  .oversized-installations-main {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .oversized-installations-gallery-image {
    max-width: 100%;
    max-height: 70vh;
    object-fit: contain;
  }
  
  /* Style for navigation buttons */
  .oversized-installations-gallery-container button {
    background-color: #fff;
    border: 1px solid #333;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    color: #333;
    padding: 10px 15px;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  }
  
  .oversized-installations-gallery-container button:hover {
    background-color: #333;
    color: #fff;
  }
  
  /* Better view mode */
  .better-view-mode {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .better-view-image {
    max-width: 80%; /* Adjust the max-width as needed */
    max-height: 80vh; /* Adjust the max-height as needed */
  }
  
  .exit-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
  }
  
  .prev-button,
  .next-button {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
  }
  
  .prev-button {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
  }
  
  .next-button {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  