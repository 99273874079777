/* HomeGallery.css */

.home-gallery-container {
  margin-top: 50px;
  background-color: #fff; /* Set background color to white */
}

.home-gallery-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.home-gallery-buttons button {
  background-color: #333;
  color: #fff;
  border: 1px solid #333;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 15px;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

.home-gallery-buttons button:hover {
  background-color: #fff;
  color: #333;
}

.home-gallery-image {
  height: calc(100vh - 60px);
  width: auto;
  object-fit: contain;
}
